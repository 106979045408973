import { Component, Injector, Input } from '@angular/core';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { ThemesLayoutBaseComponent } from '../themes/themes-layout-base.component';
import { CommonLookupServiceProxy } from '@shared/service-proxies/service-proxies';
import { Route, Router } from '@angular/router';

@Component({
    styleUrls: ['./subscription-notification-bar.component.css'],
    selector: 'subscription-notification-bar',
    templateUrl: './subscription-notification-bar.component.html',
})
export class SubscriptionNotificationBarComponent extends ThemesLayoutBaseComponent {

    @Input() customStyle = 'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px position-relative';

    public constructor(injector: Injector, _dateTimeService: DateTimeService, private _commonLookupServiceProxy:CommonLookupServiceProxy,private _router:Router) {
        super(injector, _dateTimeService);
    }

    extendTenantTrialDate(){
        this.message.confirm('', this.l('TrialConfermation'), (isConfirmed) => {
            if (isConfirmed) {
                this._commonLookupServiceProxy.extendTenantTrialDate().subscribe((result)=>{
            
                    if(result){
                        this.message.success(this.l('TenantTrialExtenction'));
                        setTimeout(()=>{
                            window.location.reload();
                        },3000);
                    }
                    else{
                        this.message.info(this.l('TenantTrialExtenctionFaild'));
                    }
                })
            }
        });  
    }
    navigateToPaymentScreen(){
        var path = `/account/buy?editionPaymentType=${this.editionPaymentType.BuyNow}&editionId=${this.appSession.tenant.edition.id}&tenantId=${this.appSession.tenant.id}`;
        window.location.href =path;
    }
}
